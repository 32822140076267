import { Box } from "@chakra-ui/react"
import React from "react"

import { DesktopNav } from "./DesktopNav"

export const NavBar = () => {

  return (
    <Box>      
      <DesktopNav /> 
    </Box>

  
  )
}


